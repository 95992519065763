<template>
  <v-container fluid>
    <form ref="validacion">
      <div class="row">
        <div class="col-2" v-if="type != 'nuevo'">
          <v-text-field
            name="fecha"
            label="Fecha"
            type="date"
            v-model="casillero.create"
            dense
            readonly
          >
          </v-text-field>
        </div>
        <div class="col-2">
          <v-text-field
            name="expediente"
            label="N° Exp."
            id="id"
            dense
            v-model="casillero.expediente"
            :readonly="type == 'ver'"
          >
          </v-text-field>
        </div>
        <div class="col-2">
          <v-text-field
            name="fechasalida"
            label="Fecha Salida"
            id="id"
            dense
            type="date"
            v-model="casillero.fechaSalida"
            :readonly="type == 'ver'"
          >
          </v-text-field>
        </div>
        <div class="col-2" v-if="type != 'ver'">
          <v-btn color="success" text @click="dialog = !dialog">Añadir </v-btn>
          <v-btn color="warning" text @click="recotizar" v-if="type == 'editar'"
            >Recotizar
          </v-btn>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-12">
        <v-data-table
          :headers="headerPrincipal"
          :items="casillero.listQuote"
          class="elevation-1"
          pagination.sync="pagination"
          :custom-filter="filterOnlyCapsText"
          :search="search"
          mobile
          fixed-header
        >
          <template v-slot:item="row">
            <tr>
              <td>
                <v-btn
                  icon
                  color="red"
                  @click="eliminar(row.item.id)"
                  :disabled="type == 'ver'"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
              <td>{{ row.item.fecha }}</td>
              <td>
                <v-autocomplete
                  :items="status"
                  v-model="row.item.status_id"
                ></v-autocomplete>
              </td>
              <!-- <td>{{ row.item }}</td> -->
              <td>{{ row.item.entitie_names }}</td>
              <td>{{ row.item.code }}</td>
              <td>{{ row.item.cliente }}</td>
              <td>{{ row.item.telefono }}</td>
              <td>{{ row.item.descripcionmercancia }}</td>
              <td width="80px">
                <v-text-field
                  v-model="row.item.peso"
                  suffix="kg"
                  @change="actualizarMontos"
                ></v-text-field>
              </td>
              <td width="80px">
                <v-text-field
                  v-model="row.item.volumen"
                  suffix="m3"
                  @change="actualizarMontos"
                ></v-text-field>
              </td>
              <td width="120px">
                <v-text-field
                  v-model="row.item.ganancia"
                  prefix="$"
                  @change="actualizarMontos"
                ></v-text-field>
              </td>
            </tr>
          </template>
          <template slot="no-data">
            <div>No se encontraron datos</div>
          </template>
        </v-data-table>
      </div>
    </div>
    <v-dialog v-model="dialog" width="75%" transition="dialog-transition">
      <v-card>
        <v-card-title primary-title>
          Listado de Cotizaciones de Casillero
        </v-card-title>
        <v-card-text>
          <v-data-table
            dense
            :headers="headers"
            :items="listadoQuoteCasillero"
            class="elevation-1"
            item-key="id"
            :search="search"
            :items-per-page="5"
          >
            <template v-slot:top>
              <v-text-field
                v-model="search"
                label="Buscar:"
                class="col-4 mx-4"
                dense
              ></v-text-field>
            </template>
            <template v-slot:item="row">
              <tr>
                <td>
                  <v-checkbox v-model="row.item.select"> </v-checkbox>
                </td>

                <!-- <td>{{ row.item.fecha }}</td> -->
                <td>{{ row.item.fecha }}</td>
                <td>{{ row.item.statusName }}</td>
                <td>{{ row.item.entitieNames }}</td>
                <td>{{ row.item.code }}</td>
                <td>{{ row.item.cliente }}</td>
                <td>{{ row.item.telefono }}</td>
                <td>{{ row.item.descripcionMercancia }}</td>
                <td>{{ row.item.peso }}</td>
                <td>{{ row.item.volumen }}</td>
                <td>{{ row.item.ganancia }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-flex text-right>
            <v-btn color="success" text @click="guardarListado()">
              Aceptar
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import funcion from "@/mixins/funciones";
export default {
  name: "nuevoCasilleroComponent",
  props: ["type"],
  mixins: [funcion],
  data() {
    return {
      dialog: false,
      selected: [],
      search: "",
      headers: [
        { value: "", text: "Seleccione" },
        { value: "fecha", text: "Fecha" },
        { value: "statusName", text: "Status" },
        { value: "entitieNames", text: "Vendedor" },
        { value: "code", text: "Código" },
        { value: "cliente", text: "Cliente" },
        { value: "telefono", text: "Teléfono" },
        { value: "descripcionMercancia", text: "Mercancia" },
        { value: "peso", text: "Peso" },
        { value: "volumen", text: "Volumen" },
        { value: "ganancia", text: "Venta" },
      ],
      headerPrincipal: [
        { value: "", text: "Acciones" },
        { value: "fecha", text: "Fecha" },
        { value: "statusName", text: "Status" },
        { value: "entitieNames", text: "Vendedor" },
        { value: "code", text: "Código" },
        { value: "cliente", text: "Cliente" },
        { value: "telefono", text: "Teléfono" },
        { value: "descripcionMercancia", text: "Mercancia" },
        { value: "peso", text: "Peso" },
        { value: "volumen", text: "Volumen" },
        { value: "ganancia", text: "Venta" },
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      this.actualizarMontos();
    }, 500);
  },
  methods: {
    ...mapActions(["recotizarCasillero"]),
    filterOnlyCapsText(value, search) {
      let text = search ? search.toLocaleUpperCase() : null;
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(text) !== -1
      );
    },
    guardarListado() {
      if (this.type == "editar") {
        this.listadoQuoteCasillero
          .filter((v) => v.select)
          .forEach((element) => {
            this.casillero.volumen += parseFloat(element.volumen);
            this.casillero.peso += parseFloat(element.peso);
            this.casillero.nroBultos += parseFloat(element.nroBultos);
            this.casillero.ganancia += element.ganancia
              ? parseFloat(element.ganancia)
              : 0;
          });
        this.listadoQuoteCasillero
          .filter((v) => v.select)
          .forEach((element) => {
            this.casillero.listQuote.push(element);
          });
      } else {
        this.listadoQuoteCasillero
          .filter((v) => v.select)
          .forEach((element) => {
            this.casillero.volumen += parseFloat(element.volumen);
            this.casillero.peso += parseFloat(element.peso);
            this.casillero.nroBultos += parseFloat(element.nroBultos);
            this.casillero.ganancia += element.ganancia
              ? parseFloat(element.ganancia)
              : 0;
          });
        this.casillero.listQuote = this.listadoQuoteCasillero.filter(
          (v) => v.select
        );
      }
      this.dialog = false;
    },
    eliminar(id) {
      this.casillero.listQuote.filter((v) => v.id == id)[0].select = false;
      this.casillero.listQuote.filter((v) => v.id == id)[0].status = false;
      this.actualizarMontos();
    },
    actualizarMontos() {
      let res = {
        volumen: 0,
        peso: 0,
        ganancia: 0,
      };

      this.casillero.listQuote
        .filter((v) => v.select)
        .forEach((element) => {
          res.volumen += parseFloat(element.volumen);
          res.peso += parseFloat(element.peso);
          res.nroBultos += parseFloat(element.nroBultos);
          res.ganancia += element.ganancia ? parseFloat(element.ganancia) : 0;
        });
      this.casillero.volumen = res.volumen;
      this.casillero.peso = res.peso;
      this.casillero.ganancia = res.ganancia;
      this.casillero.nroBultos = res.nroBultos;
    },
    recotizar() {
      this.recotizarCasillero(this.casillero.listQuote);
    },
  },
  computed: {
    ...mapState(["casillero", "listadoQuoteCasillero", "status"]),
  },
};
</script>

<style></style>
